<template>
  <b-container class="px-0">
    <b-row
      v-if="!setupGuideStep"
      align-v="center"
      align-h="between"
      class="wrapper mt-5 mb-3"
    >
      <div>
        <h1 class="font-32 mb-1 font-medium">
          {{ $t('myAdAccountsPage.setupTab.textSetUpTikTokForBusiness') }}
        </h1>
      </div>
      <div class="text-right">
        <b-img
          v-if="isEcomdyPlatform"
          fluid
          src="@/assets/images/common/ic-tiktok-partner.svg"
          alt="TikTok partner"
        />
      </div>
    </b-row>

    <b-row class="add-fund-container justify-content-center">
      <b-col
        :md="hasStepGuide ? 12 : 9"
        :class="hasStepGuide ? 'guide-list-step' : 'list-step'"
      >
        <div
          v-if="hasStepGuide"
          class="amount-balance mb-1"
        >
          <div class="d-flex align-items-center mb-1">
            <b-card-text class="m-0">
              <div class="d-flex align-items-center">
                <span>
                  {{ $t('balance.textBalancePlatform', { platformName: isEmediaPlatform ? userName : platformName }) }}:
                </span>
                <span
                  class="amount font-20 font-weight-bold"
                  data-testid="onboarding-ecomdy-balance"
                  style="margin-left: 4px;"
                >${{ amountFormat(amountBalance) }}</span>
              </div>
            </b-card-text>

            <div
              v-if="(amountBalance < minTopUp) && !isSetupCostModel"
              class="btn-auto-refund-container"
            >
              <btn-loading
                class="btn-add-fund"
                variant-convert="btn-submit-outline"
                pill
                @click="goBack"
              >
                {{ $t('payment.textAddBalance') }}
              </btn-loading>
            </div>
          </div>
          <div>
            <span
              v-if="(amountBalance < minTopUp) && !isSetupCostModel"
              class="text-warning-balance font-14"
            >*{{ $t('SMB.payment.noteAddFundGreatThanMin', { minTopUp }) }}</span>
          </div>
        </div>

        <div
          data-testid="step-connect-TikTok"
          class="step-box-1"
          :class="step === 1 && !showGuideDefault ? 'step-box-1-open' : ''"
          @mouseover="showGuideDefault = false"
          @mouseleave="showGuideDefault = true"
        >
          <div class="step-box">
            <b-row class="align-items-center">
              <b-col cols="8">
                <p
                  data-testid="title-step1"
                  class="title"
                  @click="$emit('change-step', 1)"
                >
                  <feather-icon
                    v-if="hasConnectTiktok && tiktokConnectInfo.id"
                    class="icon-check"
                    icon="CheckIcon"
                    size="24"
                  />
                  {{ $t('myAdAccountsPage.setupTab.step1.title') }}
                </p>
              </b-col>

              <b-col
                class="text-right d-flex icon justify-content-end align-items-center"
                cols="4"
              >
                <div data-testid="step1-chevron-icon">
                  <b-img
                    v-if="step === 1"
                    fluid
                    src="@/assets/images/common/ic-arrow-up.svg"
                    alt="TikTok partner"
                    @click="$emit('change-step', 1)"
                  />
                  <b-img
                    v-else
                    fluid
                    src="@/assets/images/common/ic-arrow-down.svg"
                    alt="TikTok partner"
                    @click="$emit('change-step', 1)"
                  />
                </div>
              </b-col>
            </b-row>
            <div>
              <b-collapse
                :v-model="step === 1"
                :visible="step === 1"
                data-testid="collapse-step1"
              >
                <b-overlay
                  :show="user.loading"
                  spinner-small
                  opacity=".3"
                  blur="blur"
                  rounded="sm"
                >
                  <p
                    data-testid="sub-title-step1"
                    class="mt-1 title-content-step"
                  >
                    {{ $t('myAdAccountsPage.setupTab.step1.contentStep') }}
                  </p>

                  <div
                    v-if="!hasConnectTiktok || !tiktokConnectInfo.id"
                    class="account-area d-block"
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex mw-75">
                        <b-img
                          fluid
                          src="@/assets/images/common/ic-default-avatar.svg"
                          alt="TikTok partner"
                        />

                        <p class="ml-1 mb-0">
                          {{ $t('myAdAccountsPage.setupTab.step1.textConnectTikTokForBusiness') }}
                        </p>
                      </div>
                      <btn-loading
                        data-testid="btn-connect"
                        pill
                        variant-convert="btn-submit"
                        @click="onConnect"
                      >
                        {{ $t('myAdAccountsPage.setupTab.step1.textConnect') }}
                      </btn-loading>
                    </div>
                    <hr class="hr-custom">
                    <span
                      data-testid="text-no-account"
                      class="font-14"
                    >{{ $t('myAdAccountsPage.setupTab.step1.textIfDoNotAnyAccount') }}</span>
                    <span
                      data-testid="hyperlink-create-new"
                      class="text-create-new-color font-14 cursor-pointer"
                      @click="onCreateNewBCAccount"
                    >{{ $t('guidePopUp.textCreateNew') }}.</span>
                  </div>

                  <div
                    v-else
                    class="account-area"
                  >
                    <div
                      data-testid="info-account-TT"
                      class="information"
                    >
                      <b-img
                        fluid
                        src="@/assets/images/common/ic-default-avatar.svg"
                        alt="TikTok partner"
                      />

                      <div class="ml-1">
                        <p
                          data-testid="tiktok-name"
                          class="mb-0 name"
                        >
                          {{ tiktokConnectInfo.displayName }}
                        </p>
                        <p
                          data-testid="tiktok-id"
                          class="mb-0 user-id"
                        >
                          User ID: {{ tiktokConnectInfo.id }}
                        </p>
                      </div>

                      <div />
                    </div>
                  </div>

                  <div
                    v-if="connectMessage || listBusinessAccounts.message"
                    class="error-message d-flex align-items-center"
                  >
                    <img
                      class="alert-img mr-1"
                      alt="alert"
                      :src="require('@/assets/images/icons/icon-error.svg')"
                    >

                    <small class="mt-0">
                      {{ connectMessage || listBusinessAccounts.message }}
                    </small>
                  </div>

                  <div
                    v-if="errorStep1"
                  >
                    <error-tag :error="errorStep1" />
                  </div>

                  <p
                    data-testid="text-terms"
                    class="d-block mt-1 font-14"
                  >
                    {{ $t('myAdAccountsPage.setupTab.step1.subStep') }}
                    <a
                      href="https://ads.tiktok.com/i18n/official/policy/business-products-terms"
                      target="_blank"
                      class="font-14 text-purple"
                    >
                      {{ $t('myAdAccountsPage.setupTab.step1.textTiktokTerms') }}
                    </a>
                  </p>
                </b-overlay>
              </b-collapse>
            </div>
          </div>
        </div>

        <div
          data-testid="step-create-BCId"
          class="step-box-2"
          :class="[{'disabled': !hasConnectTiktok}, hasConnectTiktok && step === 2 ? 'step-box-2-open' : '']"
          @mouseover="mouseOver"
          @mouseleave="showGuideDefault = true"
        >
          <div class="step-box">
            <b-row class="align-items-center">
              <b-col cols="8">
                <p
                  data-testid="step2-title"
                  class="title"
                  @click="$emit('change-step', 2)"
                >
                  <feather-icon
                    v-if="hasBusinessAccount"
                    class="icon-check"
                    icon="CheckIcon"
                    size="24"
                  />
                  {{ $t('myAdAccountsPage.setupTab.step2.stepTitle') }}
                </p>
              </b-col>

              <b-col
                class="text-right d-flex icon justify-content-end align-items-center"
                cols="4"
              >
                <div data-testid="step2-chevron-icon">
                  <b-img
                    v-if="step === 2"
                    fluid
                    src="@/assets/images/common/ic-arrow-up.svg"
                    alt="TikTok partner"
                    @click="$emit('change-step', 2)"
                  />
                  <b-img
                    v-else
                    fluid
                    src="@/assets/images/common/ic-arrow-down.svg"
                    alt="TikTok partner"
                    @click="$emit('change-step', 2)"
                  />
                </div>
              </b-col>
            </b-row>
            <b-collapse
              :v-model="step === 2"
              :visible="step === 2"
              data-testid="collapse-step2"
            >
              <b-overlay
                :show="loading"
                spinner-small
                opacity=".3"
                blur="blur"
                rounded="sm"
              >
                <p
                  data-testid="description-step2"
                  class="mt-1 title-content-step"
                >
                  {{ $t('myAdAccountsPage.setupTab.step2.stepContent') }}
                </p>

                <div
                  v-if="!hasBusinessAccount"
                  data-testid="no-BCId"
                  class="account-area"
                >
                  <div>
                    <span>{{ $t('myAdAccountsPage.setupTab.step2.textNoBusinessAccount') }}</span>
                  </div>
                  <btn-loading
                    data-testid="btn-create-BCId"
                    variant-convert="btn-submit"
                    @click="showAddBusinessAccount = true"
                  >
                    {{ $t('guidePopUp.textCreateNew') }}
                  </btn-loading>
                </div>

                <div
                  v-else
                  data-testid="list-BCId"
                >
                  <div
                    v-for="(businessAccount, index) in listBusinessAccounts.list"
                    :key="index"
                    class="account-area mb-1"
                  >
                    <div
                      v-if="businessAccount.bc_info.name"
                      class="information"
                    >
                      <b-img
                        fluid
                        src="@/assets/images/common/ic-default-avatar.svg"
                        alt="TikTok partner"
                      />

                      <div class="ml-1">
                        <p
                          data-testid="info-name"
                          class="mb-0 name"
                        >
                          {{ businessAccount.bc_info.name }}
                        </p>
                        <p
                          data-testid="info-user-id"
                          class="mb-0 user-id"
                        >
                          User ID: {{ businessAccount.bc_info.bc_id }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <small data-testid="text-limit-BC">{{ $t('myAdAccountsPage.setupTab.step2.subStep') }}</small>
                </div>

                <div
                  v-if="businessAccounts.length > 0"
                  class="text-center mt-1"
                >
                  <b-button
                    data-testid="btn-create-BCId"
                    class="p-0 text-create-new-color"
                    variant="text"
                    :disabled="businessAccounts.length >= 3"
                    @click="showAddBusinessAccount = true"
                  >
                    + {{ $t('guidePopUp.textCreateNew') }}
                  </b-button>
                </div>
              </b-overlay>
            </b-collapse>
          </div>
        </div>

        <div
          data-testid="step-create-ad-account"
          class="step-box-3"
          :class="[{'disabled': !hasConnectTiktok || !hasBusinessAccount}, (hasBusinessAccount || hasConnectTiktok) && step === 3 ? 'y' : '']"
          @mouseover="mouseOver"
          @mouseleave="showGuideDefault = true"
        >
          <div class="step-box">
            <b-row class="align-items-center">
              <b-col cols="8">
                <p
                  data-testid="title-step3"
                  class="title"
                  @click="$emit('change-step', 3)"
                >
                  {{ $t('myAdAccountsPage.setupTab.step3.stepTitle') }}
                </p>
              </b-col>

              <b-col
                class="text-right d-flex icon justify-content-end align-items-center"
                cols="4"
              >
                <div data-testid="step3-chevron-icon">
                  <b-img
                    v-if="step === 3"
                    fluid
                    src="@/assets/images/common/ic-arrow-up.svg"
                    alt="TikTok partner"
                    @click="$emit('change-step', 3)"
                  />
                  <b-img
                    v-else
                    fluid
                    src="@/assets/images/common/ic-arrow-down.svg"
                    alt="TikTok partner"
                    @click="$emit('change-step', 3)"
                  />
                </div>
              </b-col>
            </b-row>
            <b-collapse
              :v-model="step === 3"
              :visible="step === 3"
              data-testid="collapse-create-new"
            >
              <p
                data-testid="title-content-step"
                class="mt-1 title-content-step"
              >
                {{ $t('guidePopUp.createATikTokAdAccount.title') }}
              </p>

              <div
                v-if="!hasAdsAccounts && !showFormCreateAds"
                class="account-area"
              >
                <div data-testid="text-no-account">
                  <span>{{ $t('myAdAccountsPage.setupTab.step3.textNoAccountsFound') }}</span>
                </div>
                <btn-loading
                  data-testid="btn-create-ad-account"
                  variant-convert="btn-submit"
                  @click="showFormCreateAds = true"
                >
                  {{ $t('guidePopUp.textCreateNew') }}
                </btn-loading>
              </div>

              <div v-else>
                <add-ads-account />
              </div>
            </b-collapse>
          </div>
        </div>

        <div>
          <div
            v-if="totalAdsAccount > 0"
            data-testid="back-to-list-ad-account"
            class="d-flex align-items-center text-placeholder my-2 cursor-pointer"
            @click="handleActionBackToListAd"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              style="margin-right: 8px"
            />
            {{ $t('myAdAccountsPage.setupTab.btnBackToListAdAccount') }}
          </div>
        </div>

        <div class="user-hint mt-1 mb-2 d-flex">
          <user-hint :class="showUserGuide ? 'd-lg-none' : ''" />
        </div>
        <div
          v-if="hasStepGuide"
          class="my-2"
        >
          <btn-loading
            data-testid="text-back-to-add-fund"
            class="font-medium font-14"
            variant-convert="outline-secondary"
            pill
            @click.prevent="goBack"
          >
            {{ isSetupCostModel ? $t('stepGuideControl.stepWaitingVerify.textBackToSetupCost') : $t('stepGuideControl.stepWaitingVerify.textBackToAddFund') }}
          </btn-loading>
        </div>

      </b-col>
      <b-col
        v-if="!hasStepGuide"
        data-testid="set-up-account-user-hint"
        cols="12"
        md="9"
        :lg="hasStepGuide ? 12 : 3"
        class="payment-user-guide card-content"
      >
        <user-guide v-if="showUserGuide" />
      </b-col>
    </b-row>
    <add-business-account
      :show="showAddBusinessAccount"
      @refresh-page="handleRefreshPage"
      @close-modal="onCloseAddBusiness"
    />

    <b-modal
      id="modal-video"
      centered
      title="How to create new ad account?"
      hide-footer
      hide-header
      class="modal-video"
    >
      <div>
        <b-embed
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/PYqcc4UAuqc"
          allowfullscreen
        />
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BCollapse,
  BImg,
  BContainer,
  BRow,
  BCol,
  BButton,
  VBModal,
  BModal,
  BEmbed,
  BOverlay,
  BCardText,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'

import billingMixin from '@/mixins/billingMixin'
import envMixin from '@/mixins/envMixin'
import _isEmpty from 'lodash/isEmpty'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import adsAccount from '@/mixins/adsAccount'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import authMixin from '@/mixins/authMixin'
import UserHint from '@/components/UserHint.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import errorTag from '@/components/errorTag.vue'
import UserGuide from '@/components/UserGuide.vue'
import paymentMixin from '@/mixins/paymentMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import _get from 'lodash/get'
import AddAdsAccount from './AddAdsAccount.vue'
import AddBusinessAccount from './AddBusinessAccount.vue'

const { mapGetters } = createNamespacedHelpers('adsAccount')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
    errorTag,
    BCardText,
    BOverlay,
    BCollapse,
    BImg,
    BContainer,
    BRow,
    BCol,
    BButton,
    AddBusinessAccount,
    AddAdsAccount,
    BModal,
    BEmbed,
    UserHint,
    UserGuide,
  },

  directives: {
    'b-modal': VBModal,
  },

  mixins: [billingMixin, envMixin, stepGuideMixin, adsAccount, numberFormatMixin, authMixin, generalConfigsMixin, paymentMixin],

  props: {
    step: {
      type: Number,
      required: true,
      default: 1,
    },
    errorStep1: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      showFormCreateAds: false,
      showAddBusinessAccount: false,
      businessAccounts: [],
      showGuideDefault: true,

      contentModal: {},
      modalGuideDefault: {
        title: 'How to create ad account?',
        description: '',
        video: 'https://www.youtube.com/embed/pFqpS0sGHDU',
        guideURL: process.env.VUE_APP_CREATE_AD_ACCOUNT,
      },
      modalGuideDefaultNoStep: {
        title: 'How to create ad accounts?',
        description: '',
        video: 'https://www.youtube.com/embed/KwdaMn0qp2Q',
        guideURL: process.env.VUE_APP_CREATE_AD_ACCOUNT,
      },
      connectMessage: this.$route?.query?.error || '',
    }
  },

  computed: {
    ...mapGetters(['listBusinessAccounts', 'listAccount', 'message', 'loading']),
    ...mapGettersAuth(['user', 'isTikTokAppCenter']),

    totalAdsAccount() {
      return _get(this.listAccount, ['paging', 'total'], 0)
    },

    amountBalance() {
      return this.user?.data?.balance
    },

    hasProductLink() {
      return !_isEmpty(this.user?.data?.products?.productURLs)
    },

    isNewUser() {
      return !this.user?.data?.shouldSkipProductVerify
    },

    isWhiteListUser() {
      return this.user?.data?.whitelistProductVerify
    },

    hasAdsAccount() {
      const listAds = this.user?.data?.adsAccounts
      return Array.isArray(listAds) && listAds?.length > 0
    },
  },

  watch: {
    listBusinessAccounts: {
      handler(value) {
        this.businessAccounts = value.list
      },
      immediate: true,
    },
  },

  methods: {

    handleActionBackToListAd() {
      this.$router.push('/my-ads-account?tab=approved')
    },

    goBack() {
      if (this.isOnlyEcomdyPlatform) {
        this.$router.push('/payment/add-fund?tab=paypal')
      } else {
        this.$router.push('/payment/add-fund')
      }
    },

    onCreateNewBCAccount() {
      window.open('https://ads.tiktok.com/i18n/signup')
    },

    onCloseAddBusiness() {
      this.showAddBusinessAccount = false
    },

    handleRefreshPage() {
      this.$emit('refresh-page')
    },

    onConnect() {
      if (this.noPlan) {
        if (this.isTikTokAppCenter) {
          const idToken = localStorage.getItem('id_token')
          window.open(`${process.env.VUE_APP_SITE_URL}/billing?id_token=${idToken}`)
        } else {
          this.$router.push('/billing')
        }
      } else {
        this.$emit('connect-tiktok')
      }
    },

    openModal() {
      this.contentModal = this.hasStepGuide ? this.modalGuideDefault : this.modalGuideDefaultNoStep
      this.$refs['modal-pop-up'].openModal()
    },

    goToGuide(number) {
      switch (number) {
        case 1: return window.open('https://docs.ecomdymedia.com/ecomdy-flow-and-features/get-tiktok-agency-ad-account/')
        case 2: return window.open(process.env.VUE_APP_CREATE_AD_ACCOUNT)
        default: return null
      }
    },

    mouseOver() {
      if (!this.hasConnectTiktok) {
        this.showGuideDefault = true
      } else {
        this.showGuideDefault = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.title-content-step{
  min-width: 593.66px;

  @media (max-width: 1460px){
    min-width: 502px;
    width: 100%;
  }

  @media (max-width: 1460px){
    min-width: 100%;
  }
}

.wrapper {
  max-width: 666px;
  margin: 0 auto;
}

.guide-list-step {
  margin: 0 auto;
  max-width: 1087px;

  @media (max-width: 767px){
    max-width: 100%;
  }

  @media (min-width: 1467px) {
    max-width: max-content;
  }
}
.list-step {
  max-width: 666px;

  @media (max-width: 767px){
    max-width: 100%;
  }
}

.step-box {
  margin: 0 auto;
  padding: 24px;
  background-color: #fff;
  border-radius: var(--border-radius-base);

  &.disabled {
    opacity: 0.3;

    .title, .icon {
      pointer-events: none;
    }
  }

  @media (max-width: 1470px){
    margin: 0;
  }
}

.step-box-1, .step-box-2, .step-box-3 {
  max-width: 666px;
  &:not(:last-child) {
    padding-bottom: 20px;
  }
  &.disabled {
    opacity: 0.3;

    .title, .icon {
      pointer-events: none;
    }
  }
}

.account-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(22, 33, 62, 0.1);
  border-radius: var(--setup-guide-border-radius);
  padding: 20px;
}

.information {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .name {
    color: #16213E;
  }
  .user-id {
    color: rgba(22, 33, 62, 0.5);
  }
}

.title {
  font-size: 20px;
  color: #16213E;
  display: flex;
  align-items: center;
  margin-bottom: 0;

  img {
    margin-right: 10px;
  }
}

.title, .icon {
  &:hover {
    cursor: pointer;
  }
  img {
    padding: 10px 0;
  }
}

.error-message {
  margin-top: 10px;
  display: block;
  background: rgba(225, 28, 78, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
  small {
    display: block;
  }
}
.alert-img {
  width: 18px;
}
.warning-message {
  margin-top: 10px;
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 10px 12px 10px 16px;
}

.btn-submit {
  height: max-content;
}

.step-box {
  position: relative;
}

.hr-custom {
  border-top: 1px solid rgba(22, 33, 62, 0.1);
}

.guide-icon {
  right: 40px;
  top: 0px;
}

.img-guide-custom{
  position: relative !important;
}

.step-box-1-open:hover .tooltip-1 {
  @media (min-width: 992px) {
    display: block;
  }
}
.step-box-2-open:hover .tooltip-2 {
  @media (min-width: 992px) {
    display: block;
  }
}
.step-box-3-open:hover .tooltip-3 {
  @media (min-width: 992px) {
    display: block;
  }
}

.icon-check {
  background-color: var(--step-guide-color);
  border-radius: 100px;
  color: #fff;
  min-width: 24px;
  padding: 5px;
  margin-right: 10px;
}

.text-create-new-color {
  color: var(--text-live-chat-color);
}

.btn-auto-refund-container {
  margin-left: 12px;
}
</style>
