<template>
  <div>
    <div>
      <b-modal
        ref="add-product-link-modal"
        id="add-product-link-child"
        class="modal-child"
        hide-footer
        hide-header
        hide-header-close
        centered
      >
        <!--Content-->
        <div
          class="d-flex top-modal"
          :class="isAddProductLinkSuccess ? 'justify-content-end' : 'justify-content-between'"
        >
          <h3
            v-if="!isAddProductLinkSuccess"
            class="font-medium text-primary title-modal"
          >
            {{ $t('myAdAccountsPage.modalAddProductLink.titleModal') }}
          </h3>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideModal"
            />
          </div>
        </div>

        <div v-if="isAddProductLinkSuccess">
          <div class="content-modal-success">
            <img
              class="icon-success"
              alt="alert"
              :src="require('@/assets/images/pages/adsAccount/icon-success.png')"
            >

            <b-card-title class="title-content">
              {{ $t('myAdAccountsPage.modalAddProductLink.titleAddProductLinkSuccess') }}
            </b-card-title>
            <b-card-text class="text-content">
              {{ $t('myAdAccountsPage.modalAddProductLink.contentAddProductLinkSuccess') }}
            </b-card-text>

            <b-row class="status-product-link">
              <b-col
                md="8"
                class="link-detail p-0"
              >
                <b-card-text
                  class="link"
                  @click="goToUrl(productsData.productURL)"
                >
                  {{ productsData.productURL }}
                </b-card-text>
              </b-col>
              <b-col
                md="4"
                class="status-detail"
              >
                <b-card-text class="status-link">
                  {{ $t('myAdAccountsPage.textInReview') }}
                </b-card-text>
              </b-col>
            </b-row>
          </div>
          <!--modal footer-->
          <div class="d-flex justify-content-center">
            <b-row>
              <b-col>
                <div class="btn-control">
                  <btn-loading
                    variant-convert="btn-submit"
                    pill
                    @click="goToProductLinksPage"
                  >
                    {{ $t('myAdAccountsPage.modalAddProductLink.textSeeListProductLink') }}
                  </btn-loading>
                </div>
              </b-col>
            </b-row>
          </div>
          <!--/modal footer-->
        </div>

        <div v-else>
          <add-product-link-form
            :is-modal="true"
            @addProductLinkSuccess="addProductLinkSuccess"
          />
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import {
  BModal,
  BRow,
  BCol,
  VBTooltip,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'
import AddProductLinkForm from '@/views/product-links/components/AddProductLinkForm.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BtnLoading,
    BModal,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    // component
    AddProductLinkForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [envMixin],

  data() {
    return {
      isAddProductLinkSuccess: false,
      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },

    }
  },
  computed: {
    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },
  },

  methods: {

    addProductLinkSuccess(data) {
      this.isAddProductLinkSuccess = true
      this.productsData = data
    },

    goToProductLinksPage() {
      this.$router.push({ name: 'product-link' })
    },

    hideModal() {
      this.productsData.productURL = ''
      this.productsData.targetMarkets = []
      this.isAddProductLinkSuccess = false
      this.$refs['add-product-link-modal'].hide()
    },

    showModal() {
      this.$refs['add-product-link-modal'].show()
    },
  },
}
</script>
<style lang="scss">

  .modal-body {
    padding: 32px !important;
  }
</style>

<style lang="scss" scoped>
.top-modal {
  margin-bottom: 32px;
}

.title-modal {
  font-size: 20px;
}

.content-modal-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  .icon-success {
    max-width: 60px;
    width: 100%;
    height: auto;
  }

  .title-content{
    margin-top: 19px;
    margin-bottom: 0;
  }

  .text-content{
    text-align: center;
    margin-top: 12px;
    margin-bottom: 0;
  }

  .status-product-link {
    padding: 20px;
    border-radius: 16px;
    background-color: #16213e08;
    border: 1px solid rgba(22, 33, 62, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
    width: 100%;

    .link-detail {
      display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
      .link {
        cursor: pointer;
        color: #16213e;
        text-decoration: underline;
        font-size: 14px;
      }
    }

    .status-detail {
      padding: 6px 9.5px;
      background: #ff9903;
      border-radius: 100px;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      max-width: max-content !important;

      .status-link {
        text-transform: uppercase;
        font-size: 12px;
        color: #fff;
      }
    }
  }
}
</style>
