<template>
  <div>
    <div v-if="!hasAdsAccounts && !hasTickets">
      <b-container class="container-content">
        <b-row
          v-if="!setupGuideStep"
          align-v="center"
          align-h="between"
          class="main-header"
        >
          <b-col>
            <b-img
              fluid
              src="@/assets/images/common/ic-tiktok-inc.svg"
              alt="TikTok"
            />
          </b-col>
          <b-col class="text-right">
            <span class="font-14">by {{ companyName }}</span>
          </b-col>
        </b-row>
        <div v-if="hasMaintainMode">
          <div class="mb-2 warning-message">
            <div>
              <h5 class="mb-2 title">
                System Maintenance Announcement
              </h5>
              <p>
                Hi there <span style="width: 30px">🙌</span>
              </p>
              <p>
                Thank you so much for your interest in our service. Our system will temporarily stop offering TikTok ads accounts (the verification process will be postponed) because of maintenance. We will be back soon, hopefully, it could be a week.
              </p>
              <p>
                Please note that we will send email to new users when the system works normally again.
              </p>
              <p>
                Thank you so much for your time.
              </p>
            </div>
          </div>
        </div>
        <setup-account
          v-else
          :step="step"
          :error-step1="errorStep1"
          @change-step="changeStep"
          @connect-tiktok="handleConnectTiktok"
          @refresh-page="fetchData"
        />
      </b-container>
    </div>
    <ads-account
      v-else
      :step="step"
      :tiktok-connect-info="tiktokConnectInfo"
      :has-ads-account="hasAdsAccounts"
      :connect-message="connectMessage"
      @refresh-page="fetchAllTickets"
      @connect-tiktok="handleConnectTiktok"
      @change-step="changeStep"
    />
  </div>
</template>

<script>
import {
  BImg,
  BContainer,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import adsAccount from '@/mixins/adsAccount'
import { ADS_ACCOUNT_TICKETS_STATUS } from '@/constants'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import SetupAccount from './components/SetupAccount.vue'
import AdsAccount from './AdsAccount.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('adsAccount')
const { mapActions: mapActionsAuth, mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BImg,
    BContainer,
    BRow,
    BCol,
    SetupAccount,
    AdsAccount,
  },

  mixins: [adsAccount, envMixin, stepGuideMixin],

  data() {
    return {
      step: 0,
      connectMessage: this.$route.query.error || '',
      showSettings: false,
      errorStep1: '',
    }
  },

  computed: {
    ...mapGetters(['tiktokInfo', 'loading', 'listBusinessAccounts', 'status', 'message']),
    ...mapGettersAuth(['user']),
  },

  watch: {
    tiktokInfo(value) {
      if (value) {
        window.location.href = value.connectUrl
        this.step = 1
      }
    },

    hasConnectTiktok: {
      handler(value) {
        if (value) {
          this.showSettings = true
        }
        this.stepInitalize()
      },
      immediate: true,
    },

    tiktokConnectInfo: {
      async handler(value) {
        if (value) {
          if (value.id) {
            await this.getBusinessAccount()
          }
        }
      },
      immediate: true,
    },

    hasBusinessAccount: {
      handler() {
        this.stepInitalize()
      },
      immediate: true,
    },
  },

  created() {
    this.fetchData()
    this.stepInitalize()
    this.handleAutoConnectTikTok() // for Pancake users
  },

  methods: {
    ...mapActions(['getBusinessAccount', 'connectTiktok', 'getAccountList', 'getTicketList']),
    ...mapActionsAuth(['getAccountInfo']),

    async fetchData() {
      await this.fetchAllTickets()
    },

    stepInitalize() {
      if (!this.hasAdsAccounts && !this.hasTickets && !this.hasConnectTiktok && this.listBusinessAccounts.message) {
        this.step = 1
        this.showSettings = true
        return
      }

      if (!this.hasAdsAccounts && !this.hasTickets && this.connectMessage) {
        this.step = 1
        this.showSettings = true
      }

      if (!this.hasConnectTiktok || this.connectMessage) {
        this.step = 1
        return
      }

      if (this.hasConnectTiktok && !this.hasBusinessAccount) {
        this.step = 2
        return
      }

      if (this.hasConnectTiktok && this.hasBusinessAccount) {
        this.step = 3
      }
    },

    async fetchAllTickets() {
      const adsAccountParasm = {
        page: 1,
        size: 10,
      }
      await this.getAccountList(adsAccountParasm)

      const ticketsParams = {
        page: 1,
        size: 10,
        type: 'create_ads_account',
        status: [ADS_ACCOUNT_TICKETS_STATUS.OPEN, ADS_ACCOUNT_TICKETS_STATUS.REJECTED, ADS_ACCOUNT_TICKETS_STATUS.CLOSED],
      }
      await this.getTicketList(ticketsParams)
    },

    async handleConnectTiktok() {
      await this.connectTiktok()
      if (!this.status) {
        this.errorStep1 = this.message
      }
    },

    changeStep(step) {
      this.step = step
    },

    handleGetAdsAccount() {
      if (!this.hasConnectTiktok) {
        this.step = 1
      }
      this.showSettings = true
    },

    handleCreateTiktokAccount() {
      window.open('https://ads.tiktok.com/i18n/signup')
    },

    openLiveChat() {
      if (window.fcWidget) {
        window.fcWidget.open()
      }
    },

    handleAutoConnectTikTok() {
      if (this.user.data?.pancakeUserId && this.user.data?.registerFromService === 'PANCAKE'
        && this.user.data?.onboardingStep === 5
        && (!this.tiktokConnectInfo || !this.tiktokConnectInfo.id)
        && !this.connectMessage && this.user.data?.plans?.subscription?.status !== 'canceled') {
        this.connectTiktok()
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.main-header {
  max-width: 895px;
  margin: 0 auto;
  padding-bottom: 27px;
  border-bottom: 1px solid rgba(22, 33, 62, 0.1);

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
}
.spin-loading {
  position: absolute;
  top: 50%;
  right: 55%;
}

.container-content {
  padding: 0px;
}

.warning-message {
  background: rgba(251, 176, 59, 0.1);
  border-radius: 10px;
  padding: 22px;
}
</style>
